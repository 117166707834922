const baseURL = '';
const bucket = 'rcmtoss'
const officeServeUrl = 'https://view.officeapps.live.com/op/view.aspx'
const pdfServeUrl = 'https://v1.shufa.manage.bjsihao.cn/webPublic/pdf/web/viewer.html'  

// envType 对应的值  local dev test prod
const envType = process.env.VUE_APP_SERVER_ENV
const yyySetType = 1
const yyyNum = "6004000001"
let proxyUrl, socketUrl, pathName, curEnvType;
switch(envType){
    case 'test':
        proxyUrl = "http://101.200.212.33:8665/"
        proxyUrl = "http://v1.test.shufa.manage.bjsihao.cn/"
        //proxyUrl = "http://192.168.1.51:8665"
        socketUrl = "https://socket.liusandao.top"
        socketUrl = ""
        pathName = "shufasockettest"
        curEnvType = 22
        break
    case "dev":
        proxyUrl = "http://101.200.212.33:8665/"
        proxyUrl = "http://v1.test.shufa.manage.bjsihao.cn/"
        socketUrl = "https://socket.liusandao.top"
        socketUrl = ""
        pathName = "shufasockettest"
        curEnvType = 22
        break
    case 'local':
        proxyUrl = "http://101.200.212.33:8764/"
        //proxyUrl = "http://192.168.1.138:8764"
        socketUrl = "http://39.98.157.218:3008"
        pathName = "socketdev"
        curEnvType = 22
        break
    case 'prod':
        proxyUrl = 'http://101.200.212.33:8666/'
        proxyUrl = "https://v1.shufa.manage.bjsihao.cn/"
        //proxyUrl = "https://v1.manage.bjsihao.cn/"
        socketUrl = "https://socket.liusandao.top"
        socketUrl = ""
        pathName = "shufasocketprod"
        curEnvType = 21
        break
}
module.exports = {
    baseURL,
    bucket,
    officeServeUrl,
    pdfServeUrl,
    envType,
    proxyUrl,
    socketUrl,
    pathName,
    yyySetType,
    yyyNum,
    ossUrl: "https://rcmtoss.oss-cn-beijing.aliyuncs.com",
    curEnvType,
}